import React from "react";
import { ConfigProvider } from "antd";
import koKR from "antd/es/locale/ko_KR";
import "./App.less";

import Router from "./routes/Router";
import PersistentRecoil from "./components/PersistentRecoil";
import { SWRConfig } from "swr";
import axios from "./api/axios";

function App() {
  const swrConfig = {
    fetcher(url: string, params: object) {
      axios()
        .get(url, { params })
        .then((res) => res.data);
    },
  };

  return (
    <PersistentRecoil>
      <SWRConfig value={swrConfig}>
        <ConfigProvider locale={koKR}>
          <Router />
        </ConfigProvider>
      </SWRConfig>
    </PersistentRecoil>
  );
}

export default App;
