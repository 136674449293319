import React from 'react';
import loadable from '@loadable/component';
import {
  InfoCircleOutlined,
  PicCenterOutlined,
  CustomerServiceOutlined,
  FormOutlined,
  UserOutlined,
  BarChartOutlined,
  LayoutOutlined,
  ArrowDownOutlined,
  ShoppingOutlined,
  UsergroupAddOutlined,
  ShopOutlined
} from '@ant-design/icons';
import { PUBLIC_ROUTE } from './routes.constants';
import { Redirect } from 'react-router';

export interface ISidebar {
  label: string;
  icon?: React.ReactNode;
}

export interface IRoute {
  exact?: boolean;
  visible?: boolean;
  path?: string;
  component?: any;
  children?: Array<IRoute>;
  sidebar?: ISidebar;
}

export const publicRoutes: Array<IRoute> = [
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => <Redirect to="/login" />,
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import('../pages/auth/Login')),
  },
];

interface IPrivateRoute extends IRoute {}

export const privateRoutes: Array<IPrivateRoute> = [
  {
    exact: true,
    path: '/site',
    component: loadable(() => import('../pages/site/name')),
    sidebar: {
      icon: <InfoCircleOutlined />,
      label: '사이트 관리',
    },
    children: [
      {
        exact: true,
        path: '/name',
        sidebar: {
          label: '사이트명 관리',
        },
        component: loadable(() => import('../pages/site/name')),
      },
      {
        exact: true,
        path: '/menu',
        sidebar: {
          label: '사이트 메뉴명 관리',
        },
        component: loadable(() => import('../pages/site/menu')),
      },
      {
        exact: true,
        path: '/join/form',
        sidebar: {
          label: '회원가입 폼 관리',
        },
        component: loadable(() => import('../pages/site/join')),
      },
    ]
  },
  {
    exact: true,
    path: '/banner',
    component: loadable(() => import('../pages/banner/shop')),
    sidebar: {
      icon: <PicCenterOutlined />,
      label: '배너 관리',
    },
    children: [
      {
        exact: true,
        path: '/shop',
        sidebar: {
          label: 'SHOP 일반 배너 관리',
        },
        component: loadable(() => import('../pages/banner/shop')),
      },
      {
        exact: true,
        visible: false,
        path: '/shop/add',
        component: loadable(() => import('../pages/banner/shop/add')),
      },
      {
        exact: true,
        visible: false,
        path: '/shop/modify/:id',
        component: loadable(() => import('../pages/banner/shop/modify')),
      },
      {
        exact: true,
        path: '/shop/ad',
        sidebar: {
          label: 'SHOP 광고 배너 관리',
        },
        component: loadable(() => import('../pages/banner/shop/ad')),
      },
      {
        exact: true,
        visible: false,
        path: '/shop/ad/add',
        component: loadable(() => import('../pages/banner/shop/ad/add')),
      },
      {
        exact: true,
        visible: false,
        path: '/shop/ad/modify/:id',
        component: loadable(() => import('../pages/banner/shop/ad/modify')),
      },
      {
        exact: true,
        path: '/class',
        sidebar: {
          label: 'CLASS 일반 배너 관리',
        },
        component: loadable(() => import('../pages/banner/class')),
      },
      {
        exact: true,
        visible: false,
        path: '/class/add',
        component: loadable(() => import('../pages/banner/class/add')),
      },
      {
        exact: true,
        visible: false,
        path: '/class/modify/:id',
        component: loadable(() => import('../pages/banner/class/modify')),
      },
      {
        exact: true,
        path: '/class/ad',
        sidebar: {
          label: 'CLASS 광고 배너 관리',
        },
        component: loadable(() => import('../pages/banner/class/ad')),
      },
      {
        exact: true,
        visible: false,
        path: '/class/ad/add',
        component: loadable(() => import('../pages/banner/class/ad/add')),
      },
      {
        exact: true,
        visible: false,
        path: '/class/ad/modify/:id',
        component: loadable(() => import('../pages/banner/class/ad/modify')),
      },
    ]
  },
  {
    exact: true,
    path: '/user',
    component: loadable(() => import('../pages/user/login/history')),
    sidebar: {
      icon: <UserOutlined />,
      label: '회원 관리',
    },
    children: [
      {
        exact: true,
        path: '',
        sidebar: {
          label: '회원 리스트',
        },
        component: loadable(() => import('../pages/user')),
      },
      {
        exact: true,
        visible: false,
        path: '/modify/:id',
        component: loadable(() => import('../pages/user/modify')),
      },
      {
        exact: true,
        visible: false,
        path: '/class/progress/:id',
        component: loadable(() => import('../pages/user/class/progress')),
      },
      {
        exact: true,
        path: '/filter',
        sidebar: {
          label: '회원 조건 검색',
        },
        component: loadable(() => import('../pages/user/filter')),
      },
      {
        exact: true,
        path: '/login/history',
        sidebar: {
          label: '로그인 내역 조회',
        },
        component: loadable(() => import('../pages/user/login/history')),
      },
      {
        exact: true,
        path: '/mileage',
        sidebar: {
          label: '적립금 관리 (지급/차감)',
        },
        component: loadable(() => import('../pages/user/mileage')),
      },
      {
        exact: true,
        visible: false,
        path: '/mileage/issue',
        component: loadable(() => import('../pages/user/mileage/issue')),
      },
      {
        exact: true,
        path: '/level',
        sidebar: {
          label: '회원 등급 설정',
        },
        component: loadable(() => import('../pages/user/level/modify')),
      },
    ]
  },
  {
    exact: true,
    path: '/addition',
    component: loadable(() => import('../pages/addition/alarm/send')),
    sidebar: {
      icon: <ArrowDownOutlined />,
      label: '부가 기능',
    },
    children: [
      {
        exact: true,
        path: '/alarm',
        sidebar: {
          label: '쪽지 발송 내역',
        },
        component: loadable(() => import('../pages/addition/alarm')),
      },
      {
        exact: true,
        path: '/alarm/send',
        sidebar: {
          label: '쪽지 발송',
        },
        component: loadable(() => import('../pages/addition/alarm/send')),
      },
      {
        exact: true,
        path: '/sms',
        sidebar: {
          label: '문자 발송 내역',
        },
        component: loadable(() => import('../pages/addition/sms')),
      },
      {
        exact: true,
        path: '/sms/send',
        sidebar: {
          label: '문자 발송',
        },
        component: loadable(() => import('../pages/addition/sms/send')),
      },
      {
        exact: true,
        path: '/sms/phone',
        sidebar: {
          label: '문자 발신번호 관리',
        },
        component: loadable(() => import('../pages/addition/sms/phone')),
      },
      {
        exact: true,
        path: '/coupon',
        sidebar: {
          label: '쿠폰 리스트',
        },
        component: loadable(() => import('../pages/addition/coupon')),
      },
      {
        exact: true,
        visible: false,
        path: '/coupon/register',
        component: loadable(() => import('../pages/addition/coupon/register')),
      },
      {
        exact: true,
        visible: false,
        path: '/coupon/modify/:id',
        component: loadable(() => import('../pages/addition/coupon/modify')),
      },
      {
        exact: true,
        path: '/coupon/issue',
        sidebar: {
          label: '쿠폰 수동 발급',
        },
        component: loadable(() => import('../pages/addition/coupon/user/issue')),
      },
      {
        exact: true,
        path: '/coupon/user',
        sidebar: {
          label: '쿠폰 지급/사용 리스트',
        },
        component: loadable(() => import('../pages/addition/coupon/user')),
      },
    ]
  },
  {
    exact: true,
    path: '/order',
    component: loadable(() => import('../pages/order/class')),
    sidebar: {
      icon: <ShoppingOutlined />,
      label: '주문 관리',
    },
    children: [
      {
        exact: true,
        path: '/class',
        sidebar: {
          label: '프로그램 주문 내역',
        },
        component: loadable(() => import('../pages/order/class')),
      },
      {
        exact: true,
        path: '/class/delay',
        sidebar: {
          label: '일시정지 회원',
        },
        component: loadable(() => import('../pages/order/class/delay')),
      },
      {
        exact: true,
        visible: false,
        path: '/class/register',
        component: loadable(() => import('../pages/order/class/register')),
      },
      {
        exact: true,
        path: '/shop',
        sidebar: {
          label: '상품 주문 내역 (전체)',
        },
        component: loadable(() => import('../pages/order/shop')),
      },
      {
        exact: true,
        path: '/shop/wait',
        sidebar: {
          label: '상품 주문 내역 (입금 대기 리스트)',
        },
        component: loadable(() => import('../pages/order/shop')),
      },
      {
        exact: true,
        path: '/shop/ready',
        sidebar: {
          label: '상품 주문 내역 (상품 준비)',
        },
        component: loadable(() => import('../pages/order/shop')),
      },
      {
        exact: true,
        path: '/shop/delivery/ing',
        sidebar: {
          label: '상품 주문 내역 (배송중)',
        },
        component: loadable(() => import('../pages/order/shop')),
      },
      {
        exact: true,
        path: '/shop/delivery/complete',
        sidebar: {
          label: '상품 주문 내역 (배송 완료)',
        },
        component: loadable(() => import('../pages/order/shop')),
      },
      {
        exact: true,
        path: '/shop/cancel',
        sidebar: {
          label: '상품 주문 내역 (주문 취소)',
        },
        component: loadable(() => import('../pages/order/shop')),
      },
      {
        exact: true,
        path: '/shop/return',
        sidebar: {
          label: '상품 주문 내역 (반품)',
        },
        component: loadable(() => import('../pages/order/shop')),
      },
      {
        exact: true,
        path: '/shop/refund',
        sidebar: {
          label: '상품 주문 내역 (환불)',
        },
        component: loadable(() => import('../pages/order/shop')),
      },
      {
        exact: true,
        visible: false,
        path: '/shop/modify/:id',
        component: loadable(() => import('../pages/order/shop/modify')),
      },
    ]
  },
  {
    exact: true,
    path: '/shop',
    component: loadable(() => import('../pages/shop')),
    sidebar: {
      icon: <ShopOutlined />,
      label: 'SHOP 상품 관리',
    },
    children: [
      {
        exact: true,
        path: '',
        sidebar: {
          label: 'SHOP 판매중 상품 리스트',
        },
        component: loadable(() => import('../pages/shop')),
      },
      {
        exact: true,
        path: '/delete',
        sidebar: {
          label: 'SHOP 삭제 상품 리스트',
        },
        component: loadable(() => import('../pages/shop')),
      },
      {
        exact: true,
        path: '/main',
        sidebar: {
          label: 'SHOP 메인 노출 상품 리스트',
        },
        component: loadable(() => import('../pages/shop')),
      },
      {
        exact: true,
        visible: false,
        path: '/register',
        component: loadable(() => import('../pages/shop/register')),
      },
      {
        exact: true,
        visible: false,
        path: '/modify/:id',
        component: loadable(() => import('../pages/shop/modify')),
      },
      {
        exact: true,
        path: '/category',
        sidebar: {
          label: '카테고리 리스트',
        },
        component: loadable(() => import('../pages/shop/category')),
      },
      {
        exact: true,
        visible: false,
        path: '/category/register',
        component: loadable(() => import('../pages/shop/category/register')),
      },
      {
        exact: true,
        visible: false,
        path: '/category/modify/:id',
        component: loadable(() => import('../pages/shop/category/modify')),
      },
      {
        exact: true,
        visible: false,
        path: '/sub/category/index/:id',
        component: loadable(() => import('../pages/shop/category/sub')),
      },
      {
        exact: true,
        visible: false,
        path: '/sub/category/register/:id',
        component: loadable(() => import('../pages/shop/category/sub/register')),
      },
      {
        exact: true,
        visible: false,
        path: '/sub/category/modify/:id',
        component: loadable(() => import('../pages/shop/category/sub/modify')),
      },
      /*{
        exact: true,
        path: '/best',
        sidebar: {
          label: 'BEST SELLER 리스트',
        },
        component: loadable(() => import('../pages/shop/best')),
      },*/
      {
        exact: true,
        visible: false,
        path: '/best/register',
        component: loadable(() => import('../pages/shop/best/register')),
      },
      {
        exact: true,
        visible: false,
        path: '/best/modify/:id',
        component: loadable(() => import('../pages/shop/best/modify')),
      },
      {
        exact: true,
        visible: false,
        path: '/best/product/index/:id',
        component: loadable(() => import('../pages/shop/best/product')),
      },
      {
        exact: true,
        visible: false,
        path: '/best/product/register/:id',
        component: loadable(() => import('../pages/shop/best/product/register')),
      },
      {
        exact: true,
        visible: false,
        path: '/best/product/modify/:id',
        component: loadable(() => import('../pages/shop/best/product/modify')),
      },
      {
        exact: true,
        path: '/sender',
        sidebar: {
          label: '발송처 리스트',
        },
        component: loadable(() => import('../pages/shop/sender')),
      },
      {
        exact: true,
        visible: false,
        path: '/sender/register',
        component: loadable(() => import('../pages/shop/sender/register')),
      },
      {
        exact: true,
        visible: false,
        path: '/sender/modify/:id',
        component: loadable(() => import('../pages/shop/sender/modify')),
      },
      {
        exact: true,
        path: '/review/notice',
        sidebar: {
          label: '상품 리뷰 작성 유의사항 관리',
        },
        component: loadable(() => import('../pages/shop/review/notice')),
      },
      {
        exact: true,
        path: '/qna/notice',
        sidebar: {
          label: '상품 문의 작성 유의사항 관리',
        },
        component: loadable(() => import('../pages/shop/qna/notice')),
      },
      {
        exact: true,
        path: '/qna',
        sidebar: {
          label: '상품 문의 리스트',
        },
        component: loadable(() => import('../pages/shop/qna')),
      },
      {
        exact: true,
        visible: false,
        path: '/qna/detail/:id',
        component: loadable(() => import('../pages/shop/qna/detail')),
      },
      {
        exact: true,
        path: '/review',
        sidebar: {
          label: '상품 리뷰 리스트',
        },
        component: loadable(() => import('../pages/shop/review')),
      },
      {
        exact: true,
        visible: false,
        path: '/review/detail/:id',
        component: loadable(() => import('../pages/shop/review/detail')),
      },
      {
        exact: true,
        visible: false,
        path: '/review/modify/:id',
        component: loadable(() => import('../pages/shop/review/modify')),
      },
      {
        exact: true,
        visible: false,
        path: '/option/category/index/:id',
        component: loadable(() => import('../pages/shop/option/category')),
      },
      {
        exact: true,
        visible: false,
        path: '/option/category/register/:id',
        component: loadable(() => import('../pages/shop/option/category/register')),
      },
      {
        exact: true,
        visible: false,
        path: '/option/category/modify/:id',
        component: loadable(() => import('../pages/shop/option/category/modify')),
      },
      {
        exact: true,
        visible: false,
        path: '/option/index/:id',
        component: loadable(() => import('../pages/shop/option')),
      },
      {
        exact: true,
        visible: false,
        path: '/option/register/:id',
        component: loadable(() => import('../pages/shop/option/register')),
      },
      {
        exact: true,
        visible: false,
        path: '/option/modify/:id',
        component: loadable(() => import('../pages/shop/option/modify')),
      },
    ]
  },
  {
    exact: true,
    path: '/program',
    component: loadable(() => import('../pages/program')),
    sidebar: {
      icon: <UsergroupAddOutlined />,
      label: '프로그램 관리',
    },
    children: [
      {
        exact: true,
        path: '/',
        sidebar: {
          label: '프로그램 리스트 (전체)',
        },
        component: loadable(() => import('../pages/program')),
      },
      {
        exact: true,
        path: '/pre',
        sidebar: {
          label: '프로그램 리스트 (예정된 프로그램)',
        },
        component: loadable(() => import('../pages/program')),
      },
      {
        exact: true,
        path: '/ing',
        sidebar: {
          label: '프로그램 리스트 (판매중인 프로그램)',
        },
        component: loadable(() => import('../pages/program')),
      },
      {
        exact: true,
        path: '/end',
        sidebar: {
          label: '프로그램 리스트 (종료된 프로그램)',
        },
        component: loadable(() => import('../pages/program')),
      },
      {
        exact: true,
        path: '/main/video',
        sidebar: {
          label: '메인 영상 관리',
        },
        component: loadable(() => import('../pages/program/main/video')),
      },
      {
        exact: true,
        visible: false,
        path: '/register',
        component: loadable(() => import('../pages/program/register')),
      },
      {
        exact: true,
        visible: false,
        path: '/modify/:id',
        component: loadable(() => import('../pages/program/modify')),
      },
      {
        exact: true,
        path: '/teacher',
        sidebar: {
          label: '강사 리스트',
        },
        component: loadable(() => import('../pages/program/teacher')),
      },
      {
        exact: true,
        visible: false,
        path: '/teacher/register',
        component: loadable(() => import('../pages/program/teacher/register')),
      },
      {
        exact: true,
        visible: false,
        path: '/teacher/modify/:id',
        component: loadable(() => import('../pages/program/teacher/modify')),
      },
      {
        exact: true,
        path: '/main/type',
        sidebar: {
          label: '클래스 분류 리스트',
        },
        component: loadable(() => import('../pages/program/mainType')),
      },
      {
        exact: true,
        visible: false,
        path: '/main/type/register',
        component: loadable(() => import('../pages/program/mainType/register')),
      },
      {
        exact: true,
        visible: false,
        path: '/main/type/modify/:id',
        component: loadable(() => import('../pages/program/mainType/modify')),
      },
      {
        exact: true,
        path: '/sub/type/:id',
        visible: false,
        component: loadable(() => import('../pages/program/mainType/subType')),
      },
      {
        exact: true,
        visible: false,
        path: '/sub/type/register/:id',
        component: loadable(() => import('../pages/program/mainType/subType/register')),
      },
      {
        exact: true,
        visible: false,
        path: '/sub/type/modify/:id',
        component: loadable(() => import('../pages/program/mainType/subType/modify')),
      },
      {
        exact: true,
        path: '/course/index/:id',
        visible: false,
        component: loadable(() => import('../pages/program/course')),
      },
      {
        exact: true,
        visible: false,
        path: '/course/register/:id',
        component: loadable(() => import('../pages/program/course/register')),
      },
      {
        exact: true,
        visible: false,
        path: '/course/modify/:id',
        component: loadable(() => import('../pages/program/course/modify')),
      },
      {
        exact: true,
        visible: false,
        path: '/offline/index/:id',
        component: loadable(() => import('../pages/program/offline')),
      },
      {
        exact: true,
        visible: false,
        path: '/offline/detail/:id',
        component: loadable(() => import('../pages/program/offline/stamp')),
      },
      {
        exact: true,
        visible: false,
        path: '/option/index/:id',
        component: loadable(() => import('../pages/program/option')),
      },
      {
        exact: true,
        visible: false,
        path: '/option/register/:id',
        component: loadable(() => import('../pages/program/option/register')),
      },
      {
        exact: true,
        visible: false,
        path: '/option/modify/:id',
        component: loadable(() => import('../pages/program/option/modify')),
      },
    ]
  },
  {
    exact: true,
    path: '/stats',
    component: loadable(() => import('../pages/stats/sales/time')),
    sidebar: {
      icon: <BarChartOutlined />,
      label: '매출 관리',
    },
    children: [
      {
        exact: true,
        path: '/sales/time',
        sidebar: {
          label: '매출 통계 (시간대별)',
        },
        component: loadable(() => import('../pages/stats/sales/time')),
      },
      {
        exact: true,
        path: '/sales/day',
        sidebar: {
          label: '매출 통계 (일별)',
        },
        component: loadable(() => import('../pages/stats/sales/day')),
      },
      {
        exact: true,
        path: '/sales/month',
        sidebar: {
          label: '매출 통계 (월별)',
        },
        component: loadable(() => import('../pages/stats/sales/month')),
      },
      {
        exact: true,
        path: '/join/time',
        sidebar: {
          label: '가입 통계 (시간대별)',
        },
        component: loadable(() => import('../pages/stats/join/time')),
      },
      {
        exact: true,
        path: '/join/day',
        sidebar: {
          label: '가입 통계 (일별)',
        },
        component: loadable(() => import('../pages/stats/join/day')),
      },
    ]
  },
  {
    exact: true,
    path: '/community',
    component: loadable(() => import('../pages/community/challenge')),
    sidebar: {
      icon: <FormOutlined />,
      label: '커뮤니티 관리',
    },
    children: [
      {
        exact: true,
        path: '/challenge',
        sidebar: {
          label: '비포&애프터 챌린지 관리',
        },
        component: loadable(() => import('../pages/community/challenge')),
      },
      {
        exact: true,
        path: '/challenge/notice',
        sidebar: {
          label: '비포&애프터 챌린지 주의사항 관리',
        },
        component: loadable(() => import('../pages/community/challenge/notice')),
      },
      {
        exact: true,
        visible: false,
        path: '/challenge/add',
        component: loadable(() => import('../pages/community/challenge/add')),
      },
      {
        exact: true,
        visible: false,
        path: '/challenge/detail/:id',
        component: loadable(() => import('../pages/community/challenge/detail')),
      },
      {
        exact: true,
        visible: false,
        path: '/challenge/modify/:id',
        component: loadable(() => import('../pages/community/challenge/modify')),
      },
      {
        exact: true,
        path: '/free',
        sidebar: {
          label: '자유 관리',
        },
        component: loadable(() => import('../pages/community/free')),
      },
      {
        exact: true,
        visible: false,
        path: '/free/add',
        component: loadable(() => import('../pages/community/free/add')),
      },
      {
        exact: true,
        visible: false,
        path: '/free/detail/:id',
        component: loadable(() => import('../pages/community/free/detail')),
      },
      {
        exact: true,
        visible: false,
        path: '/free/modify/:id',
        component: loadable(() => import('../pages/community/free/modify')),
      },
      {
        exact: true,
        path: '/exercise',
        sidebar: {
          label: '운동/식단 관리',
        },
        component: loadable(() => import('../pages/community/exercise')),
      },
      {
        exact: true,
        visible: false,
        path: '/exercise/add',
        component: loadable(() => import('../pages/community/exercise/add')),
      },
      {
        exact: true,
        visible: false,
        path: '/exercise/detail/:id',
        component: loadable(() => import('../pages/community/exercise/detail')),
      },
      {
        exact: true,
        visible: false,
        path: '/exercise/modify/:id',
        component: loadable(() => import('../pages/community/exercise/modify')),
      },
      {
        exact: true,
        path: '/jellin',
        sidebar: {
          label: '젤린이 수업후기 관리',
        },
        component: loadable(() => import('../pages/community/jellin')),
      },
      {
        exact: true,
        visible: false,
        path: '/jellin/add',
        component: loadable(() => import('../pages/community/jellin/add')),
      },
      {
        exact: true,
        visible: false,
        path: '/jellin/detail/:id',
        component: loadable(() => import('../pages/community/jellin/detail')),
      },
      {
        exact: true,
        visible: false,
        path: '/jellin/modify/:id',
        component: loadable(() => import('../pages/community/jellin/modify')),
      },
      {
        exact: true,
        path: '/notice',
        sidebar: {
          label: '공지사항 관리',
        },
        component: loadable(() => import('../pages/community/notice')),
      },
      {
        exact: true,
        visible: false,
        path: '/notice/add',
        component: loadable(() => import('../pages/community/notice/add')),
      },
      {
        exact: true,
        visible: false,
        path: '/notice/detail/:id',
        component: loadable(() => import('../pages/community/notice/detail')),
      },
      {
        exact: true,
        visible: false,
        path: '/notice/modify/:id',
        component: loadable(() => import('../pages/community/notice/modify')),
      },
      {
        exact: true,
        path: '/jj',
        sidebar: {
          label: '제이제이공간 관리',
        },
        component: loadable(() => import('../pages/community/jj')),
      },
      {
        exact: true,
        visible: false,
        path: '/jj/add',
        component: loadable(() => import('../pages/community/jj/add')),
      },
      {
        exact: true,
        visible: false,
        path: '/jj/detail/:id',
        component: loadable(() => import('../pages/community/jj/detail')),
      },
      {
        exact: true,
        visible: false,
        path: '/jj/modify/:id',
        component: loadable(() => import('../pages/community/jj/modify')),
      },
      {
        exact: true,
        path: "/calendar",
        sidebar: {
          label: "일정 캘린더 관리",
        },
        component: loadable(() => import("../pages/community/calendar")),
      },
      {
        exact: true,
        path: "/calendar/memo",
        sidebar: {
          label: "일정 캘린더 메모 관리",
        },
        component: loadable(() => import("../pages/community/calendar/MemoPage")),
      },
      {
        exact: true,
        path: '/menu',
        sidebar: {
          label: '커뮤니티 메뉴명 관리',
        },
        component: loadable(() => import('../pages/community/menu')),
      },
      {
        exact: true,
        path: '/banner',
        sidebar: {
          label: 'CLASS 커뮤니티 배너 리스트',
        },
        component: loadable(() => import('../pages/community/banner')),
      },
      {
        exact: true,
        path: '/open',
        sidebar: {
          label: '커뮤니티 공개 여부 관리',
        },
        component: loadable(() => import('../pages/community/open')),
      },
    ]
  },
  {
    exact: true,
    path: '/popup',
    component: loadable(() => import('../pages/popup')),
    sidebar: {
      icon: <LayoutOutlined />,
      label: '팝업 관리',
    },
    children: [
      {
        exact: true,
        path: '',
        sidebar: {
          label: '팝업 관리',
        },
        component: loadable(() => import('../pages/popup')),
      },
      {
        exact: true,
        visible: false,
        path: '/register',
        component: loadable(() => import('../pages/popup/register')),
      },
      {
        exact: true,
        visible: false,
        path: '/modify/:id',
        component: loadable(() => import('../pages/popup/modify')),
      },
    ],
  },
  {
    exact: true,
    path: '/cs',
    component: loadable(() => import('../pages/cs/qna')),
    sidebar: {
      icon: <CustomerServiceOutlined />,
      label: '고객센터 관리',
    },
    children: [
      {
        exact: true,
        path: '/faq',
        sidebar: {
          label: '자주 묻는 질문 관리',
        },
        component: loadable(() => import('../pages/cs/faq')),
      },
      {
        exact: true,
        visible: false,
        path: '/faq/register',
        component: loadable(() => import('../pages/cs/faq/register')),
      },
      {
        exact: true,
        visible: false,
        path: '/faq/modify/:id',
        component: loadable(() => import('../pages/cs/faq/modify')),
      },
      {
        exact: true,
        path: '/qna',
        sidebar: {
          label: '1:1 문의 관리',
        },
        component: loadable(() => import('../pages/cs/qna')),
      },
      {
        exact: true,
        visible: false,
        path: '/qna/detail/:id',
        component: loadable(() => import('../pages/cs/qna/detail')),
      },
      {
        exact: true,
        path: '/class',
        sidebar: {
          label: '수업 질문 관리',
        },
        component: loadable(() => import('../pages/cs/class')),
      },
      {
        exact: true,
        visible: false,
        path: '/class/detail/:id',
        component: loadable(() => import('../pages/cs/class/detail')),
      },
    ],
  },
];
